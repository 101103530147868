import { http } from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const supplierAppServices = {
    getProfile() {
        return http.get(`${API_ROOT}/supplier/profile`).then(resp => {
            return resp
        })
    },
    updateInfo(payload) {
        return http.put(`${API_ROOT}/supplier/profile/info`, payload).then(resp => {
            return resp
        })
    },
    updateSecuritySetting(payload) {
        return http.put(`${API_ROOT}/supplier/profile/security-setting`, payload).then(resp => {
            return resp
        })
    },
    updateValidDomain(payload) {
        return http.put(`${API_ROOT}/supplier/profile/valid-domain`, payload).then(resp => {
            return resp
        })
    },
    updateThreshold(payload) {
        return http.put(`${API_ROOT}/supplier/profile/threshold`, payload).then(resp => {
            return resp
        })
    },
}

export default supplierAppServices
